<template>
  <!-- Header -->
  <div class="flex flex-nowrap text-teal">
    <div class="flex justify-center flex-1">
      <p class="text-center text-orange text-4xl font-bold p-6">
        Policies
      </p>
    </div>
    <div class="flex items-center w-16">
      <router-link :to="{ name: 'cs-signatureList' }">
        <span class="material-icons text-3xl cursor-pointer">
          grading
        </span>
      </router-link>
    </div>
    <div @click="toggleModal()" class="flex items-center justify-center w-16">
      <span class="material-icons text-3xl cursor-pointer">
        note_add
      </span>
    </div>
  </div>
  <!-- Content -->
  <div
    v-if="!isLoading"
    class="w-10/12 md:w-7/12 flex flex-col m-auto border-t-2 border-r-2 border-l-2 rounded-b-l rounded-t-xl px-2"
  >
    <div
      v-for="policy in getPolicies"
      :key="policy.id"
      class="flex justify-between flex-nowrap text-grey border-b-2 py-3"
    >
      <div class="flex w-1/2 md:w-1/3 items-center pl-2">
        <span class="material-icons text-3xl text-red">
          picture_as_pdf
        </span>
        <!-- <a :href="policy.attachment" target="_blank"
            ><p class="pl-2">{{ policy.name }}</p></a
          >-->
        <router-link
          :to="{
            name: 'cs-policy',
            params: { policyId: policy.id },
            query: { policy: policy.attachment },
          }"
        >
          <p class="pl-2">{{ policy.name }}</p></router-link
        >
      </div>
      <div class="flex w-1/3 items-center">
        <p class="hidden sm:block">
          Uploaded:
          <strong>{{ convertedTime(policy.created_at) }}</strong>
        </p>
      </div>
      <div
        v-if="!isLoading"
        class="flex w-1/2 md:w-1/3 justify-start items-center  cursor-pointer"
      >
        <!-- Start Tool -->
        <div @click="showSignatureLog(policy)">
          <p>
            Signed by:
            <span v-if="policy.signatures.length <= 1">
              <strong v-for="value in policy.signatures" :key="value.id">
                {{ value.user.name }},
              </strong>
            </span>
            <span v-else>
              <span
                v-for="(value, signaturesIndex) in policy.signatures"
                :key="value.id"
              >
                <strong v-if="signaturesIndex <= 0"
                  >{{ value.user.name }},
                </strong>
              </span>
              <strong> +{{ policy.signatures.length - 1 }}</strong>
            </span>
          </p>
          <div
            v-bind:class="{
              hidden: signatureMiniModalShow !== policy.id,
            }"
          >
            <signature-tooltip
              :policiesSignature="policy.signatures"
            ></signature-tooltip>
          </div>
        </div>
        <!-- END Tooltip -->
      </div>
    </div>
  </div>

  <br />
  <!-- <my-pagination
    v-if="!!getPolicies.total"
    :options="options"
    v-model="page"
    :records="getPolicies.total"
    :per-page="getPolicies.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else> </blank-paginate> -->
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
// import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
import signatureTooltip from "./signatureTooltip.vue";
import moment from "moment";
export default {
  props: {
    getPolicies: {
      type: Object,
      required: true,
    },
    hideModal: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["signature-modal-close"],
  components: { signatureTooltip },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      page: 1,
      signatureMiniModalShow: 0,
      isLoading: false,
      error: null,
    };
  },
  created() {
    // this.loadPolicies();
  },
  methods: {
    // myCallback(e) {
    //   this.loadPolicies(e);
    // },
    // async loadPolicies(e) {
    //   this.isLoading = true;
    //   try {
    //     await this.$store.dispatch("cleaner/loadPolicies", e);
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.error = error.message || "Something went wrong!";
    //   }
    // },
    handleError() {
      this.error = null;
    },
    showSignatureLog(el) {
      if (this.signatureMiniModalShow === el.id) {
        this.signatureMiniModalShow = 0;
      } else {
        this.signatureMiniModalShow = el.id;
      }
    },
    toggleModal() {
      this.$emit("signature-modal-close");
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    policyLink(id) {
      return {
        params: { policyId: id },
      };
    },
  },
};
</script>
