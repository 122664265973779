<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="Oops. Something went wrong"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <teleport to="body">
    <sign-policy-modal
      :getPolicies="getPolicies"
      :hideModal="hideModal"
      :class="{ hidden: hideModal }"
      @submit-modal="submitModal"
      @close-modal="toggleModal"
    ></sign-policy-modal>
  </teleport>
  <template v-if="!isLoading">
    <cleaner-policies
      :hideModal="hideModal"
      :getPolicies="getPolicies"
      @signature-modal-close="toggleModal"
    ></cleaner-policies>
  </template>
  <span v-else>
    <heart-rate fast="true"></heart-rate>
  </span>
</template>
<script>
import cleanerPolicies from "@/components/layout/CS/cleanerPolicies.vue";
import signPolicyModal from "@/components/modal/signPolicyModalCleaner.vue";
export default {
  components: { signPolicyModal, cleanerPolicies },
  data() {
    return {
      signatureModalShow: false,
      hideModal: true,
      error: null,
      isLoading: false,
    };
  },
  created() {
    this.loadPolicies();
  },
  methods: {
    async loadPolicies() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadPolicies");
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    handleError() {
      this.error = null;
    },
    showSignatureLog() {
      this.signatureModalShow = !this.signatureModalShow;
    },
    toggleModal() {
      this.hideModal = !this.hideModal;
    },
    async submitModal(actionPayload) {
      this.hideModal = !this.hideModal;
      try {
        await this.$store.dispatch("cleaner/signPolicy", actionPayload);
        await this.$store.dispatch(
          "cleaner/loadPolicies",
          this.$route.query.page
        );
        this.$toast.success(`Policy Signed`);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getPolicies() {
      return this.$store.getters["cleaner/getPolicies"];
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #425563 !important;
  font-weight: 700;
}
</style>
