<template>
  <form @submit.prevent="submitModal">
    <div
      class="flex justify-center h-screen w-screen items-center z-20 absolute top-0 left-0 -translate-x-1/2"
    >
      <div
        class="h-screen w-screen  bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-lighGrey shadow-xl z-20"
      >
        <!-- Modal Header -->
        <div
          class="flex flex-row justify-between p-6 bg-lightGrey border-b border-lightGrey rounded-tl-lg rounded-tr-lg"
        >
          <p class="font-bold text-teal">Signature Book</p>
          <span class="material-icons cursor-pointer" @click="closeModal">
            close
          </span>
        </div>
        <!-- Modal Body -->

        <div class="flex flex-col px-6 py-5 bg-white">
          <div class="flex flex-col sm:flex-row items-center mb-5 sm:space-x-5">
            <div class="w-full  mt-2 sm:mt-0">
              <label class="font-semibold text-grey" for="documentSelect"
                >Select Policy</label
              >

              <select
                name="documentSelect"
                required
                class="w-full p-5 bg-white border text-darkGrey border-lightGrey rounded shadow-sm appearance-none mt-2"
                v-model="selectedPolicy"
              >
                <option disabled value="">Please select one</option>
                <option
                  v-for="policy in getPolicies"
                  :key="policy.id"
                  :value="policy.id"
                  >{{ policy.name }}</option
                >
              </select>
            </div>
          </div>
          <label class="mb-2 font-semibold text-grey" for="comment"
            >Comment</label
          >
          <textarea
            type="text"
            name="comment"
            placeholder="Type message..."
            class="p-5 mb-5 bg-white border border-lightGrey text-darkGrey rounded shadow-sm h-36"
            v-model="comment"
          ></textarea>

          <hr />

          <div class="flex items-center mt-5 mb-3 space-x-4">
            <input
              class="inline-flex rounded-full"
              type="checkbox"
              name="confirmationTickBox"
              id="confirmationTickBox"
              v-model="confirmationTickBox"
            />
            <label
              class="cursor-pointer inline-flex font-semibold text-grey"
              for="confirmationTickBox"
            >
              I confirm that I have read and understood above selected policy </label
            ><br />
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-end p-5 bg-white border-t border-lightGrey rounded-bl-lg rounded-br-lg"
        >
          <p
            class="px-11 py-2 text-white font-semibold bg-orange rounded-full cursor-pointer"
            @click="closeModal"
          >
            Cancel
          </p>
          <button
            type="submit"
            class="disabled:opacity-50 px-12 py-2 ml-3 text-white font-semibold bg-teal rounded-full"
            :disabled="!confirmationTickBox"
            @click="submitModal"
          >
            Sign
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  emits: ["close-modal", "submit-modal"],
  props: {
    getPolicies: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPolicy: "",
      comment: "",
      confirmationTickBox: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      if (!this.confirmationTickBox || !this.selectedPolicy) {
        return;
      } else {
        let formdata = new FormData();
        formdata.append("comment", this.comment);
        formdata.append("confirmation", +this.confirmationTickBox);
        formdata.append("policy_id", this.selectedPolicy);
        this.$emit("submit-modal", formdata);
        this.selectedPolicy = "";
        this.comment = "";
        this.confirmationTickBox = false;
      }
    },
  },
};
</script>
