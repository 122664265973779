<template>
  <div
    class="z-10 md:w-64 absolute right-0 sm:right-auto  shadow-lg bg-white border-2 border-grey rounded"
  >
    <div class="bg-teal text-center p-2">
      <p class="text-sm font-bold text-white pb-1">
        Signatures
      </p>
    </div>
    <ul class="text-xs leading-4 text-grey py-3 pl-3 pr-3">
      <li>
        <div
          v-for="(value, index) in policiesSignature"
          :key="value.id"
          class="flex justify-between"
        >
          <div>{{ index + 1 }}: {{ value.user.name }}</div>
          <div>- {{ convertedTime(value.created_at) }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    policiesSignature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>
